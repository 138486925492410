html,
body {
    height:100% !important; /* cross-browser */
    background-color: whitesmoke;
}

#root{
    min-height: 100%;
    height:100% !important; /* cross-browser */
    height: 100%; /* cross-browser */
}

.App {
    text-align: center;
    justify-content: center;
    min-height: 100%;
    height:100% !important; /* cross-browser */
    height: 100%; /* cross-browser */
    background-color: whitesmoke;
}



ul {
    list-style: none;
}

ul li{
    position: relative;
    padding-left: 20px
}

ul li:after{
    content:'✓';
    font-size: 1em;
    position: absolute;
    left: 0;
    top: 0;
    color: green
}

